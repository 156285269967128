import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const ButtonExternal = makeShortcode("ButtonExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "château-de-morey-charme--prestige-de-france"
    }}><strong parentName="h1">{`Château de Morey Charme & Prestige de France`}</strong></h1>
    <h2 {...{
      "id": "a-history-and-a-future"
    }}>{`A history and a future`}</h2>
    <h3 {...{
      "id": "quick-presentation"
    }}>{`Quick presentation`}</h3>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": "100%",
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/h0Wnp2wRXgw?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`Located in Lorraine, the Château de Morey has been welcoming you within these walls since 1998.`}</p>
    <p>{`Between Nancy and Metz, relax in one of its 5 spacious guest rooms, accompanied by its bucolic park and its activities (mini-golf, swimming pool, SPA, equestrian activities, table football, billiards, boules).`}</p>
    <p>{`An exceptional stopover, the 16th century castle, former residence of the Dukes of Lorraine, easily accessible (5 km from the A31 on the D44a). Isolated from the town yet only 20 minutes from Nancy and 10 minutes from several local restaurants, enjoy a fabulous moment of sweetness in a certain calm.`}</p>
    <p>{`The Château is more precisely located in the commune of Belleau, at an altitude of 305 metres, which offers a breathtaking view of the Natagne valley which spreads out the charms of Lorraine.`}</p>
    <p>{`You can also organise your receptions in a residence that has been hosting weddings, seminars and other events for about twenty years.`}</p>
    <p>{`Today, receiving you within these walls so that you can spend a moment of wonder is the priority of the Château de Morey. A place combining calm and charm creating a stay that will allow you to recharge your batteries.`}</p>
    <ButtonInternal to="/en/bed-and-breakfast/" mdxType="ButtonInternal">Discover our guest rooms</ButtonInternal>
    <ButtonInternal to="/en/halls/" mdxType="ButtonInternal">Discover our room rentals</ButtonInternal>
    <h2 {...{
      "id": "its-history"
    }}>{`Its history`}</h2>
    <p><img parentName="p" {...{
        "src": "../../content/assets/chateau_de_morey.jpg",
        "alt": "Château de Morey"
      }}></img><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`In 12th century Lorraine, a castle is said to have been built on the ruins of a Templar convent. It would have been the residence of the Sire of Morey Jean de Toulon at the time when the property included the castle, the farm and the Romanesque church of the 12th. It was then the Joly family who separated the three estates and the writer Ducrocq who succeeded them.`}</p>
    <p>{`Victim of a fire in 1985, after having been abandoned for 8 years, the castle was renovated by the Karst family who today run the commercial activity of the castle.`}</p>
    <p>{`In the forest above the castle, discover vestiges of history. On foot, by bike or on horseback, walk along the forest paths to see old war trenches, secret galleries, and more…`}</p>
    <p>{`The history of the Château de Morey stretches from near and far. And yes, Lorraine is a region with a heavy past. It has known many wars, occupations and mysterious stories. From the Middle Ages to the present day, through many eras, you can discover the secrets of this region.`}</p>
    <p>{`In the heart of the Meurthe et Moselle, the castle tends to continue to bear fruit from its history, accompanying you in your intimate moments, celebrating your weddings, accompanying your evenings… A vast garden supported by a wall more than 5 metres high. Chestnut trees, Lebanese cedar, lime trees, purple beeches, oaks, maple and a duck pond create a unique space at Château de Morey.`}</p>
    <ButtonInternal to="/en/newsletter/" mdxType="ButtonInternal">Join the newsletter</ButtonInternal>
    <h2 {...{
      "id": "his-current-life"
    }}>{`His current life`}</h2>
    <p><img parentName="p" {...{
        "src": "../../content/assets/2020/04/tourelle2.jpeg",
        "alt": "Château de Morey"
      }}></img><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`Today the Château de Morey continues to welcome you to its premises and does everything possible to ensure that you have an unforgettable time. From the reception to its services, live an authentic and spectacular stay within the stone walls of the residence.`}</p>
    <p>{`The domain is as much a place to rest as it can be transformed into a festive place. In addition to the reception of your events, we regularly organise evenings or brunches for the pleasure of sharing good times with you.`}</p>
    <p>{`The Château is always in the process of being improved and is planning to redo its roof. For this purpose, a kitty has been created in order to enable this to be done.`}</p>
    <ButtonExternal href="https://www.leetchi.com/c/projets-de-chateau-de-morey-22970336" mdxType="ButtonExternal">Participate</ButtonExternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      